<template>
  <v-app>
    <v-layout wrap>
      <v-flex md8 xs0>
        <v-container
          fill-height
          fluid
          style="padding: 20%"
          :style="{ 'background-color': primaryContainerColor}"
        >
          <v-row align="center" style="letter-spacing: 1px;">
            <v-layout wrap>
              <v-flex xs12
                :style="{ 'color': secundaryContainerColor}"
              >
                <h4>Descubre las oportunidades de la comunicaci�n digital</h4>
              </v-flex>
              <v-flex xs12
                style="margin: 20px 0;opacity: 0.5;"
                :style="{ 'color': secundaryContainerColor}"
              >
                Dise�amos soluciones a tu medida de f�cil y r�pida gestion. <br />
                �adem�s son totalmente personalizables!
              </v-flex>
              <v-flex xs12
                :style="{ 'color': secundaryContainerColor}"
              >
                <v-btn outlined :color="secundaryContainerColor" x-large
                  href="https://descubregroup.com/soluciones-integrales-de-comunicacion-digital/"
                >
                  Descubrir
                </v-btn>    
              </v-flex>
              <v-flex
                xs12
                :style="{ 'color': secundaryContainerColor}"
                style="opacity: 0.5;position:fixed;bottom: 50px"          
              >
                <span v-html="footerInfo" />
              </v-flex>
            </v-layout>
          </v-row>
        </v-container>

        <!-- <v-card
          :color="mainColor"
          elevation="0"
          height="calc(100vh)"
          style="border-radius:0"
        >
          <v-card-title class="justify-center">
            <h4>Descubre las oportunidades de la comunicaci�n digital</h4>
          </v-card-title>
        </v-card> -->
      </v-flex>
      <v-flex md4 xs12>
        <v-container
          fill-height
          fluid
          style="padding: 20%"
          :style="{ 'background-color': secundaryContainerColor}"
        >
          <v-row align="center" style="letter-spacing: 1px;">
            <v-layout wrap>
              <v-flex xs12 style="margin-bottom:20px">
                <v-icon :color="primaryContainerColor" x-large style="font-size: 50px;">mdi-account-circle</v-icon>
                <br />
                <span style="font-size:20px;font-weight: 700;" :style="{ 'color': primaryContainerColor }">Sign in</span>
                <!--v-alert
                  outlined
                  :color="primaryContainerColor"
                  style="border-radius:0"
                >
                  <v-icon :color="primaryContainerColor" x-large>mdi-account-circle</v-icon>
                </v-alert-->
              </v-flex>
              <v-flex xs12>
                <v-form>
                  <v-text-field
                    v-model="username"
                    v-bind="{ 'outlined' : true}"
                    :label="$t('login.username')"
                    name="login"
                    outlined
                    type="text"
                    v-on:keyup.enter="handleLogin"
                  />
                  <v-text-field
                    id="password"
                    v-bind="{ 'outlined' : true}"
                    v-model="password"
                    :label="$t('login.password')"
                    name="password"
                    :type="passwordType"
                    :append-icon="passwordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
                    v-on:keyup.enter="handleLogin"
                    @click:append="handleShowPassword"
                  />
                </v-form>
                <v-btn
                  block
                  :color="primaryContainerColor"
                  @click="handleLogin"
                  style="margin-top: 0px;margin-bottom: 20px;"
                  :style="{ 'color': secundaryContainerColor}"
                >
                  {{ $t('login.login') }}
                </v-btn>
                <center>
                  <v-alert
                    v-if="error && error.length > 0"
                    text
                    type="error"
                    icon="mdi-alert-circle"
                  >
                  {{ $t(error) }}
                  </v-alert>
                </center>
                <v-btn
                  v-if="isDev"
                  outlined
                  rounded
                  color="primary"
                  block
                  @click="setAdmin"
                  style="margin-top: 0px;margin-bottom: 20px;"
                >
                  aa
                </v-btn>
                <v-btn
                  v-if="isDev"
                  outlined
                  rounded
                  color="secondary"
                  block
                  @click="setNormal"
                  style="margin-top: 0px;margin-bottom: 20px;"
                >
                  bb
                </v-btn>
              </v-flex>
            </v-layout>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-app>
  <!-- <v-app style="background-color:#689e99;">
    <div>
    <v-main>
      <v-container fluid fill-height >
        <v-layout align-center justify-center >
          <v-flex
            xs12
            style="padding-top:40px;"
          >
            <center>
              <v-img
                width="267px"
                :src="'./img/logodtouch.png'"
              />
            </center>
            <center style="color: white; margin-bottom:20px;">
              <div v-html="headerInfo" />
            </center>
            <center>
            <v-card
              style="background-color:#689e99;"
              :elevation="0"
              max-width="600px"
            >
              <v-card-text>
                <v-form>
                  <v-text-field
                    dark
                    v-model="username"
                    :label="$t('login.username')"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-on:keyup.enter="handleLogin"
                  />
                  <v-text-field
                    dark
                    id="password"
                    v-model="password"
                    :label="$t('login.password')"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-on:keyup.enter="handleLogin"
                  />
                </v-form>
              </v-card-text>
            </v-card>
            <center>
              <v-btn
                v-if="isDev"
                outlined
                rounded
                color="white"
                @click="setAdmin"
                style="margin-top: 0px;margin-bottom: 20px;"
              >
                aa
              </v-btn>
              <v-btn
                v-if="isDev"
                outlined
                rounded
                color="white"
                @click="setNormal"
                style="margin-top: 0px;margin-bottom: 20px;"
              >
                bb
              </v-btn>
              <v-btn
                outlined
                rounded
                color="white"
                @click="handleLogin"
                style="margin-top: 0px;margin-bottom: 20px;"
              >
                {{ $t('login.login') }}
              </v-btn>
            </center>
            </center>
            <center style="color: white; margin-top:20px;">
              <div v-html="footerInfo" />
            </center>
            <br>
            <center>
              <alert
                v-if="error && error.length > 0"
                style="max-width:567px !important;"
                error
                :text="error"
              />
            </center>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    </div>
  </v-app> -->
</template>

<script>
  import * as sha1 from 'js-sha1'
  import axios from 'axios'
  import utils from '@/services/utils.js'
  import api from '@/services/api.js'
  export default {
    data () {
      return {
        passwordType: 'password',
        secundaryContainerColor: '#FFFFFF',
        username: null,
        password: null,
        error: null
      }
    },
    computed: {
      primaryContainerColor () {
        return process.env.VUE_APP_PRIMARYCOLOR
      },
      isDev () {
        return process.env.VUE_APP_ENV === 'development'
      },
      headerInfo () {
        return process.env.VUE_APP_LOGIN_HEADER
      },
      footerInfo () {
        return process.env.VUE_APP_LOGIN_FOOTER
      }
    },
    mounted () {
      // this.$vuetify.theme.themes.light.primary = '#4caf50' // TODO
      // this.$vuetify.theme.themes.light.secondary = '#00FF00'
      api.setToken(null)
    },
    methods: {
      setNormal () {
        this.username = 'normal'
        this.password = '2222'
        this.handleLogin()
      },
      setAdmin () {
        this.username = 'admin'
        this.password = '1111'
        this.handleLogin()
      },
      handleShowPassword () {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
      },
      handleLogin () {
        this.error = null
        const body = {
          name: this.username,
          password: this.password ? sha1(this.password) : null
        }
        if (!this.username || !this.password || this.username.length === 0 || this.password.length === 0) this.error = 'login.messages.required'        
        axios.post(api.getAPIURL('system', 'v1/authenticate'), body)
        .then(response => {
          const { user, token, path } = response.data
          if (!user) this.error = 'login.messages.userNotFound'
          else {
            api.setToken(token)
            utils.setUser(user)
            this.$router.push(path)
          }
        })
        .catch((err) => {
          if (err) this.error = 'login.messages.userNotFound'
        })
      }
    }
  }
</script>
